<template>
  <div class="homer">
    <div class="h-bar-0-mobile">
      <h1 class="new_top_head" style="text-decoration: none">Signup</h1>
    </div>

    <img
      src="../assets/Welcome.png"
      class="m_welcome"
      style="width: 135px; margin-bottom: 20px"
    />
    <div v-if="$root.step == 2" class="buccbutton" @click="$root.step = 1">
      <i class="material-icons hifer">arrow_back</i>Back
    </div>
    <div v-if="$root.step === 1">
      <div style="color: white; filter: grayscale(0.5)">
        <div class="con_box" style="background: black">
          <i class="material-icons">email</i>
          <input
            style="background: none !important"
            class="email_innner_box"
            v-model="$root.credentials.email"
            type="text"
            placeholder="Email"
            name="eml"
            required
          />
        </div>
      </div>

      <div class="con_box" style="background: black">
        <i class="material-icons">account_box</i>
        <input
          style="background: none"
          class="email_innner_box"
          v-model="$root.credentials.username"
          type="text"
          placeholder="Username"
          name="uname"
          required
        />
      </div>

      <button class="login_btn1" @click="nextStep">Next</button>

      <div class="irp" style="margin-top: 20px">
        <a style="color: aliceblue"
          >Have an account?
          <span
            class="log"
            style="cursor: pointer"
            @click="$root.modal_open = 'login'"
          >
            Login</span
          ></a
        >
      </div>
    </div>

    <div v-if="$root.step === 2">
      <!-- Username and password screen -->

      <div class="container">
        <div class="_bisde">
          <input
            class="con_box"
            style="background: #303533; outline: none !important"
            v-model="$root.credentials.f_name"
            type="text"
            placeholder="First name"
            name="fn"
            required
          />
        </div>
        <div class="_bisde">
          <input
            class="con_box"
            style="background: #303533; outline: none !important"
            v-model="$root.credentials.l_name"
            type="text"
            placeholder="Last name"
            name="ln"
            required
          />
        </div>
      </div>

      <div class="con_box" style="    background: rgb(48, 53, 51);
    outline: none !important;">
        D.o.B:
        <input
          class="email_innner_box2"
          style="
            padding-right: 0px;
            margin-left: 20px;
            width: 60px;
            outline: none !important;
          "
    
          placeholder="Date"
          name="dob"
          v-model="$root.credentials.day_birth"
          required
        />
        <select
          style="outline: none !important"
          v-model="$root.credentials.month_birth"
          class="email_innner_box2"
        >
          <option
            style="background-color: #f0f0f0; color: #000"
            value=""
            disabled
            selected
          >
            Month
          </option>
          <option style="background-color: #f0f0f0; color: #000" value="Jan">
            January
          </option>
          <option style="background-color: #f0f0f0; color: #000" value="Feb">
            February
          </option>
          <option style="background-color: #f0f0f0; color: #000" value="Mar">
            March
          </option>
          <option style="background-color: #f0f0f0; color: #000" value="Apr">
            April
          </option>
          <option style="background-color: #f0f0f0; color: #000" value="May">
            May
          </option>
          <option style="background-color: #f0f0f0; color: #000" value="Jun">
            June
          </option>
          <option style="background-color: #f0f0f0; color: #000" value="Jul">
            July
          </option>
          <option style="background-color: #f0f0f0; color: #000" value="Aug">
            August
          </option>
          <option style="background-color: #f0f0f0; color: #000" value="Sep">
            September
          </option>
          <option style="background-color: #f0f0f0; color: #000" value="Oct">
            October
          </option>
          <option style="background-color: #f0f0f0; color: #000" value="Nov">
            November
          </option>
          <option style="background-color: #f0f0f0; color: #000" value="Dec">
            December
          </option>
        </select>
        <input
          class="email_innner_box2"
          v-model="$root.credentials.year_birth"
          style="
            outline: none !important;
            margin-left: 10px;
            padding-right: 0px;
            width: 70px;
          "
      
          placeholder="Year"
          name="dob"
          required
        />
      </div>

      <div class="dweeew">
        <input
          class="con_box"
          v-model="$root.password"
          :type="show_passworde ? 'text' : 'password'"
          style="    background: rgb(48, 53, 51);
    outline: none !important;"
          placeholder="●●●●●●●"
          required
        />
        <button class="view_boxy" @click="toggle_password_show">
          <i v-if="!show_passworde" class="material-icons">remove_red_eye</i>
          <i v-else class="material-icons">visibility_off</i>
        </button>
      </div>
      <div
        v-if="$root.password_check.length != 0 && $root.password"
        class="wrong_password_box2"
      >
        <a style="color: rgb(207, 15, 15)">Password needs:</a
        ><a
          style="color: rgb(231, 172, 8)"
          v-for="check in $root.password_check"
          v-bind:key="check.id"
          >{{ check[0] }}</a
        >
      </div>

      <div style="margin-bottom: 15px" class="txt__">
        <template v-if="!($root.password_check.length != 0 && $root.password)">
          <input
            type="checkbox"
            id="jack"
            value="Jack"
            style="cursor: pointer"
            v-model="$root.credentials.accept_terms"
          />
          <label class="check_font" for="jack"
            >I Agree To
            <router-link
              style="
                background-color: black !important;
                color: white !important;
              "
              to="/terms-of-service"
              >Terms of Service</router-link
            > </label
          ><br /><br />
          <input
            type="checkbox"
            style="cursor: pointer"
            id="john"
            value="John"
            v-model="$root.credentials.over_18"
          />
          <label class="check_font" for="john">I am 18+ years of age</label>
        </template>
      </div>

      <button
        class="login_btn1"
        style="margin-bottom: 10px"
        @click="$root.create_account()"
        v-if="!(password_check.length != 0 && $root.password)"
        type="submit"
      >
        Create Account
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "CreateAComponent",
  data() {
    return {
      password_check: [],
      checkedNames: [],
      show_passworde: false,
    };
  },
  methods: {
    toggle_password_show() {
      this.show_passworde = !this.show_passworde;
    },
    nextStep() {
  // Check if all fields in step 1 are filled
  if (!this.$root.credentials.username || !this.$root.credentials.email) {
    this.$toast.show("Please fill in all the details", {
      position: "bottom",
      duration: 1500,
    });
  } else if (!/^[a-zA-Z0-9_]*$/.test(this.$root.credentials.username)) {
    this.$toast.show(
      "Invalid Username, Please use only letters, numbers, and underscores for the username. You may NOT use '-'",
      {
        position: "bottom",
        duration: 3500,
      }
    );
  } else if (this.$root.validateEmail(this.$root.credentials.email)) {
    this.$toast.show(`This is not a valid email.`, {
      position: "bottom",
      duration: 1500,
    });
  } else if (this.$root.credentials.username.length < 5) {
    this.$toast.show(`You must have more than 4 letters in your username`, {
      position: "bottom",
      duration: 1500,
    });
  } else if (this.$root.credentials.username.length > 20) {
    this.$toast.show(
      `You must can't have more than 20 letters in your username`,
      { position: "bottom", duration: 1500 }
    );
  } else {
    // Send request to check username and email
    axios.post(`${this.$root.API_PREFIX}/api/validate_userandEmail`, {
      username: this.$root.credentials.username,
      email: this.$root.credentials.email,
    })
    .then(response => {
      if (response.data.message =="valid") {
        this.$root.step = 2; // Proceed to the next step
      } else {

  

        this.$toast.show(response.data.message, {
          position: "bottom",
          duration: 1500,
        });
      }
    })
    .catch(error => {
      console.error("There was an error!", error);
      this.$toast.show(error.data, {
          position: "bottom",
          duration: 1500,
        });


      
    });
  }
},
    isUpper(str) {
      return !/[A-Z]/.test(str);
    },
  },
};
</script>

<style scoped>
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.warning_txtt {
  font-size: 12px;
  color: grey;
  font-style: oblique;
}

.dweeew {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.email_innner_box {
  border-radius: 5px;
  height: 30px;
  background: #111b11;
  display: flex;
  align-items: center;
  color: white !important;
  font-size: 21px;
  font-family: "Gilroy";
  padding: 5px 20px;
  justify-content: center;
  width: calc(100% - 40px);
  border: none;
  outline: none;
}
.homer {
  max-width: 1280px;
  margin: 0px;
  width: 100%;
}
.check_font {
  color: #d2d2d2 !important;

  font-family: "Gilroy";
  font-size: 16px;
}
.irp {
  border: 1px solid #575757;
  border-radius: 5px;
  height: 30px;
  background: #060a06;
  display: flex;
  align-items: center;
  color: white;
  font-size: 16px;
  padding: 0px 20px;
  justify-content: center;
}
.new_top_head {
  font-family: "Gilroy", cursive;
  font-weight: 100;
  font-style: normal;
  letter-spacing: 0px;
  font-size: 24px;
  color: #ffffff;
}

.h-bar-0-mobile {
  display: none;
}
@media only screen and (max-width: 600px) {
  .h-bar-0-mobile {
    height: 50px;
    align-items: center;
    justify-content: center;
    background-color: #111b11;
    flex-shrink: 0;
    box-sizing: border-box;
    touch-action: none;
    padding: 0px 20px;
    color: white !important;
    box-shadow: unset;
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
  }
  .homer {
    max-width: 1280px;
    margin: auto;
    width: 90%;
  }
  ._bisde {
    margin: 0px;
  }
}

.big_wrapper {
  display: flex;
  align-self: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  margin-top: -50px;
}
.btn_wrapper {
  width: 1600px !important;
  padding: 0px;
  margin: auto;
  margin-bottom: auto;
  margin-bottom: 25px;
}

.btn_style {
  text-align: center;
  padding: 10px 30px;
  font-family: monospace;
  color: #00ffdb;
  background: #202020;
  border: 1px solid white;
}

.main_text2 {
  color: aliceblue;
  font-family: monospace;
  font-variant: petite-caps;
  font-size: 120px;
  font-style: oblique;
  text-decoration: blink;
  background: -webkit-linear-gradient(#fafafa, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  margin: 15px 0px;
}
.a_ref_cf {
  width: 100%;
  text-align: center;
  display: block;
}
.logozw {
  width: 90%;
  margin-top: 30px;
  padding: 20px;
  background: #373737;
  box-shadow: 5px 16px 150px 5px #050b1047;
  color: white;
  border: 1px solid #281616;
  border-radius: 8px;
  max-width: 350px;
}
.wrappere {
  display: flex;
  justify-content: space-between;
}
.home {
  max-width: 1280px;
  margin: auto;
  margin-top: auto;
  margin-bottom: auto;
  width: 80%;
  margin-bottom: 50px;
}

.e2side {
  width: 100%;
}

.txt__ {
  font-size: 15px;
  font-family: monospace;
  color: white;
}
.container {
  display: flex;
  flex-direction: row;
  max-width: 920px;
  width: 100%;
  justify-content: space-between;
}
.looloo {
  width: 470px;
}
._bisde {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 49%;
}

.buccbutton {
  display: flex;
  background: #171917;
  cursor: pointer;
  justify-content: center;
  width: 100px;
  margin: 20px;
  padding: 10px 7px;
  margin-left: 0px;
  margin-top: 0px;
  border-radius: 12px;
  border: 2px dashed grey;
}
.eqw__holder {
  flex: none;
  background: #8c8c8c;
  outline: 3px solid #2d2d2d;
  box-shadow: 5px 16px 150px 5px #050b10;
  color: white;
  width: 1600px;
  padding: 0px;
  margin: auto;
}
.text_for_h2 {
  padding-bottom: 10px;
  font-family: monospace;
}
.wrong_password_box2 {
  margin: 0px;
  background: #3838383d;
  color: #686868;
  border: 1px solid;
  padding: 4px;
  font-family: "Gilroy", cursive;
  text-align: center;
  font-size: 14px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}
b {
  color: white;
}
.con_box {
  margin-bottom: 15px;
  border: 1px solid #262626;
  border-radius: 5px;
  height: 30px;
  background: #111b11;
  display: flex;
  align-items: center;
  color: #a8a8a8;
  font-size: 21px;
  font-family: "Gilroy";
  padding: 5px 20px;
  justify-content: center;
  width: calc(100% - 40px);

  font-size: 18px;
}
.login_btn {
  color: aliceblue;
  font-family: monospace;
  width: 100%;
  background: #228d04;
  padding: 19px 0px;
  text-align: center;
  margin-top: 20px;
  border: 1px solid;
  border-radius: 11px;
  width: 280px;
}
.log {
  color: #66d345;
}

.login_btn1 {
  color: #42da3b;
  font-size: 16px;
  background: #268126;
  border: 1px dashed #242424;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  margin: 0px;
  color: #42da3b;
  font-size: 16px;
  background: #053b24;
  border: 1px dashed #000000;
}

.main_text {
  color: aliceblue;
  font-family: monospace;
  background: #686868;
  padding: 20px 20px;
  margin-top: 20px;
  margin-top: 0px;
}

.links {
  color: lightgrey !important;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-direction: row;
}
.router-link-exact-active {
  font-weight: 500;
  background-color: #4da24d !important;
  color: #8cff64 !important;
}
.btn__ {
  color: #000000;
  font-size: 13px;
  background: #d8d8d8;
  border: 1px dashed #777777;
  cursor: pointer;
  border-radius: 10px;
  padding: 10px;
  text-decoration: none;
  margin-right: 10px;
}
.h_link {
  color: #b8b8b8;
}
.logoz {
  width: 51%;
}

.email_innner_box2 {
  width: 100%;
  padding: 0px;
  border-radius: 5px;
  height: 30px;
  background: #111b11;
  display: flex;
  align-items: center;
  color: white;
  font-size: 21px;
  font-family: "Gilroy";
  justify-content: center;
  border: none;
  font-size: 16px;    background: inherit;
  color: #8d8d8d;
}

.spacer {
  color: aliceblue;
  font-family: monospace;
  padding: 14px;
}
.l_bar {
  width: 100%;
  height: 2px;
  background: #42b983;
  margin: 10px 0px;
  margin-top: 30px;
}

.view_boxy {
  margin-bottom: 5px;
  border: 1px solid #262626;
  border-radius: 5px;
  height: 30px;
  background: #000000;
  display: flex;
  cursor: pointer;
  align-items: center;
  color: #36d83b;
  font-family: "Gilroy";
  margin-left: 15px;
  justify-content: center;
  padding: 20px 20px;
  margin-right: 0px;
}
.l2_bar {
  width: 100%;
  height: 2px;
  background: #939393;
  margin: 10px 0px;
  margin-top: 30px;
}
.links2 {
  color: lightgrey !important;
  justify-content: space-evenly;
  margin: auto;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
}

h1 {
  margin: 40px 0 0;
  text-align: center;
  display: flex;
  font-size: 30px;
  font-family: monospace;
  color: #9c9c9c;
  font-style: oblique;
  text-decoration: underline;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.legal_tc {
  margin-top: 20px;
  font-family: monospace;
  color: #bcbcbc;
  width: 733px;
}
.hello {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .hello {
    display: flex;
    margin-top: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
  .m_welcome {
    width: 105px !important;
    text-align: center;
    margin-top: 20px;

  }

  .btn__ {
    margin: 0;
    margin-bottom: 20px;
    padding: 6px;
    font-size: 12px;
  }
  .weq_eqw {
    margin-top: 20px;
  }
}
.home {
  margin-top: 0px !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}
</style>
