<template>
  <div class="roulette-wrapper">
    <div class="roulette-wheel" style="    margin-left: 50%;
    width: 0px;">
      <div class="images" ref="images" :style="wheelStyle">
        <div
          v-for="(value, index) in squareValues"
          :key="index"
          :class="getSquareClass(index)"
          class="square"
        >
          <span><img class="price_LOGO" src="@/assets/gemstone.png">{{ value.toFixed(2) }}</span>
        </div>
      </div>
      <div class="selector-line"></div>
    </div>
    
    <template v-if="!$root.user.name">
    <br>
      <a style="font-size: 21px;color: white;">Please login to daily spin.</a>

      <br> 
      <button class="spnBTUN"    :disabled="true">
      Spin the Wheel
    </button>
    </template>
    
    <template v-else>

    <!-- Spin Button -->
    <button class="spnBTUN" v-tooltip="'Already done'" @click="spinRoulette" :disabled="hasSpun || !spinReady">
      Spin the Wheel
    </button>

    <!-- Display message if spin is not ready -->
    <div v-if="!spinReady" style="font-size: 24px; margin: 20px 0px;">
      <a style="font-size: 14px;color: beige;">You have already done your daily spin.</a><br>
      Please wait {{ timeLeft }}
    </div></template>
  </div>
</template>

<script>
import axios from 'axios'; // Ensure axios is installed and imported

export default {
  data() {
    return {
      totalSquares: 40, // Total number of squares in the roulette
      squareValues: [], // Values for each square
      winningIndex: 34, // Predefined winning square index
      winningValue: null, // Will be set after API call
      minValue: 0.01,
      maxValue: 90.03,
      currentRotation: 0, // Tracks the rotation of the wheel
      isDimmed: false, // Optional visual effect
      wheelStyle: { transform: 'translateX(0px)' },
      hasSpun: false, // Track if the user has already spun
      spinReady: false, // Track if the daily spin is ready
      timeLeft: '', // Time left for next spin
    };
  },
  mounted() {
    this.generateRandomValues();
    this.wheelStyle = { transform: `translateX(-1800px)` };
    this.checkDailySpinStatus(); // Check spin status on mount
  },
  methods: {

    fetchWinningValue() {
    return axios.post(`${this.$root.API_PREFIX}/api/daily_spin`, {
      token: this.$cookies.get("token"),
    })
      .then(response => { 
        if(response.data.payout)  {
          this.winningValue = parseFloat(response.data.payout);
          this.squareValues[this.winningIndex] = this.winningValue; // Update the winning value in squares
        } else {
          this.$toast.show(response.data.message, { position: "bottom", duration: 1500 });
        }

      })
      .catch(err => {
        console.error("Error fetching winning value:", err);
        throw err; // Rethrow to prevent the spin from continuing if API fails
      });
  },
    // Function to assign random values to each square
    generateRandomValues() {
      for (let i = 0; i < this.totalSquares; i++) {
        if (i === this.winningIndex) {
          // Predefined value for the winning square, initially null until API call
          this.squareValues.push(this.winningValue || 0);
        } else {
          let randomValue;
          const randomChance = Math.random();

          if (randomChance < 0.8) {
            randomValue = Math.random() * (0.1 - 0.01) + 0.01;
          } else if (randomChance < 0.95) {
            randomValue = Math.random() * (20 - 0.1) + 0.1;
          } else if (randomChance < 0.995) {
            randomValue = Math.random() * (50 - 20) + 20;
          } else {
            randomValue = Math.random() * (200 - 50) + 50;
          }

          this.squareValues.push(randomValue);
        }
      }
    },

    // Returns the class for each square
    getSquareClass(index) {
      console.log(index)
      return 'normal-square';
      // return index === this.winningIndex ? 'winning-square' : 'normal-square';
    },

    // Check the status of the daily spin
    checkDailySpinStatus() {
      return axios.post(`${this.$root.API_PREFIX}/api/daily_spin_status`, {
        token: this.$cookies.get("token"),
      })
        .then(response => {
console.log(response.data)

          if (response.data.ready) {
            this.spinReady = true; // Spin is ready
            this.timeLeft = '0h 0m'; // Reset the time left
          } else {
            this.spinReady = false; // Spin is not ready
            this.timeLeft = response.data.timeLeft; // Set the time left from the API
          }
        })
        .catch(err => {
          console.error("Error checking daily spin status:", err);
        });
    },


    
    // Function to spin the wheel
    spinRoulette() {
      // Fetch the winning value before spinning
      this.fetchWinningValue()
        .then(() => {
          if (this.winningValue !== null) { // Ensure winningValue is set
            const images = this.$refs.images;
            this.hasSpun = true;

            // Reset the transform to 0px
            images.style.transition = 'transform 0s ease-out';
            images.style.transform = 'translateX(0px)';
            void images.offsetWidth; // Force reflow

            const squareWidth = 102; // Width of each square
            const randomOffset = Math.floor(Math.random() * (80 - 20 + 1)) + 20; // Random offset between 20 and 80
            const winnerPosition = this.winningIndex * squareWidth + randomOffset; // Position of the winning square

            images.style.transition = 'transform 8s cubic-bezier(0.10, 1.0, 0.6, 1)';
            this.wheelStyle = { transform: `translateX(-${winnerPosition}px)` };

            setTimeout(() => {
              // Add the amount they won
              this.$root.updateBalance(this.winningValue);

              this.spinReady = false; // Spin is not ready
              this.timeLeft = '23h 59m'; // Set the time left from the API
            }, 8000); // 8 seconds (8000ms)
          } else {
            console.error('Winning value was not set.');
          }
        })
        .catch(err => {
          console.error('Failed to spin the wheel due to an API error.' + err);
        });
    },
  },
};
</script>


<style scoped>
.roulette-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

button:disabled {
  opacity: 0.5;
  cursor: unset;
}

.roulette-wheel {
  display: flex;
  width: fit-content;
}

.square {
  width: 100px;
  height: 100px;
  border: 1px solid #333;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.winning-square {}

.images {
  display: flex;
  flex-direction: row;
  transition: transform 4s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.normal-square {
  background: #010201;
  color: white;
}

.selector-line {
  position: absolute;
  width: 2px;
  height: 100px;
  background-color: red;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.spnBTUN {
  width: 100%;
  color: #42da3d;
  font-size: 19px;
  background: #053b24;
  border: 1px dashed #000000;
  cursor: pointer;
  border-radius: 10px;
  margin: 0px;
  margin-top: 20px;
}
</style>
