<template>

  <div class="wdnhwdafis"><img class="price_LOGO" src="../assets/gemstone.png"><input class="none" :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)" :placeholder="placeholder" :type="type"></div>



</template>

<script>
export default {
  name: 'FancyInputComponent',
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Enter text...'
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  emits: ['update:modelValue']
}
</script>

<style scoped>
.wdnhwdafis {
  border: 1px solid #25c100;
  border-radius: 5px;
  background: #111b11;
  display: flex;
  align-items: center;
  color: white;
  font-size: 18px;
  font-family: monospace;
  padding: 0px 20px;
  justify-content: center;
  margin: 0px;
  height: 45px;


}

.none {
  border: none;
  background: none;
  color: white;
  font-size: 24px;
  width: 100%;
  outline: none !important;
}

@media only screen and (max-width: 600px) {

  .none {
    font-size: 15px;
  }



}
</style>